<template>
  <div id="triviaScreen" :style="{ backgroundImage: backgroundUrl }">

    <!-- Fondo video loop -->
    <Transition name="slideleft">
      <VideoBackgroundVue v-show="ShowVideoLoop" ref="videoBackground" :playlist="options.standByVideos" :leaderboard="options.leaderboard" />
    </Transition>

    <!-- Usuarios que estan por jugar -->
    <Transition name="fade">
      <ScreenQueue v-if="ShowVideoLoop && players.length && !bigParty">

        <template v-slot:bottom>
          <div class="has-text-weight-bold is-size-3">{{ $t('exp_trivia.challengeOthers') }}</div>
          <div class="timer" v-if="players.length >= options.queue.minPlayers">
            <b-tag class="has-color-black-bis" size="is-large">
              <d-icon
              icon="FaClock"
              size="is-small"
              class="ml-3 mr-1"/> 
              {{ remainingTimeToStart }}
            </b-tag>
            <div class="is-size-5">{{ $t('exp_trivia.toStart') }}</div>
          </div>
          <div v-else class="waiting is-size-4">{{ $t('exp_trivia.waitingPlayers') }}</div>
        </template>

      </ScreenQueue>

      <div class="user-conected" v-if="ShowVideoLoop && bigParty">
        <div class="userNumber">{{ players.length  }}</div>
        <div class="users">{{ $tc('system.screenUsersConnected',players.length) }}</div>
      </div>  
    </Transition>

    <Transition :name="options.config.getReadyDuration > 2 ? 'slideright-outleft':'fade'">
      <!-- GET READY SCREEN -->
      <div v-if="!ShowVideoLoop && currQuestionIndex == -1 && options.config.getReadyDuration > 0" id="preGameScreen">
        <div class="get-ready-box">
          <div class="get-ready">{{ $t('exp_trivia.letStart') }}</div>
          <div class="get-ready-extra-text" v-if="options.config.getReadyExtraText">{{ options.config.getReadyExtraText }}</div>
        </div>
      </div>
    </Transition>
    <!-- JUEGO -->
    <!-- SCREEN COMPONENT -->
    <component v-show="currQuestionIndex >= 0" :is="options.screenDisplay.type" :class="options.screenDisplay.type"/>

    <qr-box :hidden="HideQr" :position="bigParty? 'center' : 'auto'" :size="bigParty? 'large' : 'auto'"/>

    <div id="cosaspreload" style="display:none">
      <b>OPTION IMAGES</b><br/>
      <div v-for="(question, i) in this.questions" :key="i">
        <img v-if="question.image" :src="question.image.sizes.high.url" style="width:120px"/>
        <img v-if="question.image" :src="question.image.url" style="width:120px"/>
      </div>
      <div v-if="options.screenDisplay.type == 'screenCityTiles'">
        <b>ANIMATIONS</b><br/>
        <div v-for="cities in options.animations" :key="cities.id">
          <video autoplay muted v-for="city in cities.states"  :key="city.id" crossorigin="anonymous" style="width:120px">
              <source :src="city.animation.url" type="video/webm" />
          </video>
        </div>
        <b>TRANSITIONS</b><br/>
        <video autoplay muted crossorigin="anonymous" v-for="transition in options.transitions" :key="transition.id" style="width:120px">
              <source :src="transition.animation.url" type="video/webm" />
        </video>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import VideoBackgroundVue from './VideoBackground.vue';
import ScreenQueue from './screenQueue.vue';
import PlayerAvatar from '../PlayerAvatar.vue';
import TriviaOptionsShuffle from '../controllers/TriviaOptionsShuffle';
import "./screenTrivia.scss";
import ConfettiGenerator from "confetti-js";
import qrBox from './qrBox.vue';
import Timeout from 'await-timeout';

import screenTriviaDefaultGame from './screenTriviaDefaultGame.vue';

// ELIJO PREGUNTAS SEGUN EL MODO DE SORT
// Bucket sort elije un limite de preguntas de manera continua, para evitar que entre ronda y ronda hayan repetidas
// Shuffle sort mezcla todo y envia un numero de preguntas segun el limite
const SORT_FUNCTION = 'bucket'; // bucket o suffle

export default {
  components: {
    VideoBackgroundVue,
    ScreenQueue,
    PlayerAvatar,
    qrBox,
    screenTriviaDefaultGame,
    screenDefault: () => import("./screenTriviaDefaultGame.vue"),
    screenCityTiles: () => import("./screenTriviaCityTilesGame.vue"),
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      questions: [],
      remainingTimeToStart: "00:00",
      remainingTimeQuestion: 1, // normalizado 0-1
      pausedNormalized: null,
      questionClockRunning: false,
      questionState: -1, // 0: get ready,  1: prompt (pregunta grande), 2: options, 3: result
      questionMs: 0,
      questionIndex: 0,
      currQuestionIndex: -1,
      currQuestion: null,
      timerWebWorkers: [],
      playersGuess: {},
      playersScoreboard: [],
      questionCountdownStarted: false,
      asyncTimer: null,
      playersAnswered: 0,
      selectedQuestions: [],
      gameSeed: null,
      questionSeed: null,
    };
  },
  computed: {
    OptionsToShow(){
      // Esta funcion devuelve el array de preguntas a mostrar
      // Una vez que termina el termina solo devuelve la correcta
      // EN principio solo se esta utilizando para CityTiles
      if(this.questionState < 3){
        return this.currQuestion.options
      }
      // Lista filtrada
      // $parent.currQuestion.id
      return this.currQuestion.options.filter(x => x.id == this.currQuestion.id)
    },
    clockNormalized(){
      return this.remainingTimeQuestion
      // return (this.pausedRemainingTimeQuestion || this.remainingTimeQuestion)
    },
    clockDisplay(){
      return Math.round(this.clockNormalized * (this.questionMs / 1000))
    },
    bigParty() {
      return this.options.queue.maxPlayers > 20
    },
    HideQr() {
      if(this.options.screenDisplay.type == "screenCityTiles" && this.serverState > 1) return true
      if(this.bigParty && this.serverState > 1 ) return true

      return false
    },
    videoVolume() {
      return !this.ShowVideoLoop ? 0 : this.$store.getters['space/config'].space.volume / 100; // todo fade out volumen?
    },
    serverState() {
      return this.$store.state.space.serverState;
    },
    ShowVideoLoop() {
      if (this.serverState < 2) return true
      return false
    },
    players() {
      return this.$store.state.space.players.filter(x => !x.queue)
    },
    questionStateClass() {
      const states = { 0: 'stategetready', 1: 'stateprompt', 2: 'stateoptions', 3: 'stateresult' };
      return states[this.questionState];
    },
    backgroundUrl() {
      return this.options.screenBackground ? 'url(' + encodeURI(this.options.screenBackground.url) + ')' : "url(/assets/trivia/background.png)" ;
    }
  },
  methods: {
    ShowConfetti() {
      // https://github.com/Agezao/confetti-js
      var confettiSettings = { target: 'confetti-canvas' };
      var confetti = new ConfettiGenerator(confettiSettings);
      confetti.render();
    },
    DisplayScore(val) {
      return Math.round(val);
    },
    intToChar(i) {
      return TriviaOptionsShuffle.intToChar(i)
    },
    ResetGame() {
      this.currQuestionIndex = -1;
      this.questionState = -1;
      this.SyncQuestionStatePlayers();
      // Le guardo a cada jugador su indice. Sirve en CityTiles para definir la ciudad que le toca
      this.players.forEach((player, i) => {
        this.$store.commit("space/AddPlayerData", {
            dbid: player.dbid, // Para encontrar al usuario
            index: i, // TODO este i puede venir de alguna lista medio azarosa que los mezcle
          })
      })
      this.playersScoreboard = JSON.parse(JSON.stringify(this.players))
    },
    async StartGame() {
      this.ResetGame()
      this.players.forEach(player => {
        // Inicializo un diccionario dbid y un array de nulls segun la cantidad de preguntas
        // Ejemplo
        // this.playersGuess["bWjsdkal23"] = [null, null, null]
        this.playersGuess[player.dbid] = new Array(this.selectedQuestions.length).fill(null)
      })
      this.questionState = 0;
      this.SyncQuestionStatePlayers()
      if (this.serverState < 2) return;

      if (this.options.config.getReadyDuration > 2) {
        this.$sfxPlay('countdown321')
      }

      const getReadyDuration = (this.options.config.getReadyDuration || 0) * 1000;


      await this.$timeout(getReadyDuration);
      this.NextQuestion();
    },
    async NextQuestion() {
      // Chequeo si no se fueron antes en medio del loop
      this.questionSeed = (Math.random() + 1).toString(36).substring(2);
      if (this.serverState < 2) return
      this.questionCountdownStarted = false
      this.playersAnswered = 0;
      this.pausedNormalized = null
      this.remainingTimeQuestion = 1

      // Secuencia de timers que manejan el estado dentro de la pregunta
      this.currQuestionIndex++
      await this.ShuffleQuestion()
      this.remainingTimeToStart = (this.questionMs / 1000).toString()

      // show prompt
      this.questionState = 1;

      this.$sfxPlay('newquestion')


      this.SyncQuestionStatePlayers()
      this.$socket.client.emit("roomPlayersMessage", { type: "questionDuration", questionMs: this.questionMs })
      await this.$timeout(2000);
      if (this.serverState < 2) return;

      // show options
      this.questionState = 2;
      this.SyncQuestionStatePlayers()
      const showOptionsTime = 100 + (this.currQuestion.options.length * 800);
      await this.$timeout(showOptionsTime);
      if (this.serverState < 2) return;

      const startTime = this.$time.now();
      
      const endTime = startTime + this.questionMs
      this.timerWebWorkers.postMessage({ endTime, delay: this.$time.delay });
      this.questionCountdownStarted = true

      this.$sfxPlay('questionmusic')
      this.$socket.client.emit("roomPlayersMessage", { type: "questionEndTime", endTime, startTime })

      this.questionClockRunning = true
      if(this.asyncTimer){
        this.asyncTimer.clear()
      }
      this.asyncTimer = new Timeout();
      this.asyncTimer.set(this.questionMs)
        .then(() => {
          if (this.serverState < 2) return;
          this.questionClockRunning = false
          this.ShowResults()
        })
    },
    async ShowResults() {
      // Frenar musica de fondo
      this.$sfxStop('questionmusic')
      // Sonido bang
      this.$sfxPlay('stopmusicbang')
      // mostrar los avatares de que respondio cada uno
      console.log("question time finished", this.$time.now())
      // show result
      this.questionState = 3;
      // Calculate points
      await this.$timeout(500);
      this.SyncQuestionStatePlayers()
      await this.CheckScore();
      this.SendStandings()
      
      // Me aseguro que despues del ultimo que ve correcto/incorrecto hayan
      // al menos 8 segundos, que es el tiempo que la animacion en mobile
      const showAnswerTimer = 3500 - ((this.options.config.answerResultInterval || 0) * 1000)
      await this.$timeout(showAnswerTimer);
      
      console.log("YA TERMINO LOS PUNTAJOS")

      if (this.serverState < 2) return;

      if (this.currQuestionIndex < this.selectedQuestions.length - 1) {
        this.NextQuestion()
      } else {
        // Era la ultima pregunta. Termino el juego
        // Creo una copia del scoreboard (por si algun jugador se va mientras se muestra)
        this.playersScoreboard = JSON.parse(JSON.stringify(this.players)).sort((a, b) => b.score - a.score);
        // Le aviso al servidor
        this.$socket.client.emit("space:MatchFinish", {
          spaceSlug: this.$store.state.space.spaceSlug,
          // opcional envio user scores para sus achievements
          achievements: this.players.map((player) => {
            return {
              dbid: player.dbid,
              score: this.DisplayScore(player.score),
              socketid: player.socketid
            }
          })
        });
        await this.$timeout(1000);

        if(this.playersScoreboard[0].score > 0 || this.players.length == 1) this.ShowConfetti()
        this.$sfxPlay('winnerpodium')

        this.$refs.videoBackground.rerenderLeadeboard()
      }
    },
    ShuffleQuestion() {
      const i = this.currQuestionIndex < 0 ? 0 : this.currQuestionIndex;
      const q = this.selectedQuestions[i]
      this.currQuestion = TriviaOptionsShuffle.GetOptions(q, this.questionSeed);
      this.questionMs = this.GetCurrentQuestionTime()

      console.log("🃏 Shuffled questions", q, this.currQuestionIndex, this.currQuestion)
    },
    GetCurrentQuestionTime() {
      // TODO segun servidor o
      return (this.currQuestion.overrideTime || this.options.config.timePerQuestion) * 1000
      // return 10 * 1000
    },
    CheckScore() {
      const PUNTOS_RESPUESTA_CORRECTA = 200;
      const PUNTOS_EXTRA_TIEMPO = 400;
      const interval = (this.options.config.answerResultInterval || 0) * 1000; // tiempo entre que se muestra correcto/incorrecto de los jugadores
      return new Promise( (outerResolve) => {
        // https://stackoverflow.com/a/45500721
        var promise = Promise.resolve();
        var i = 0;
        var next = () => {
          if(!this.players[i]) return;
          var player = this.players[i];
          console.log("CHEQUEANDO PUNTAJE DE", player.username)
          let questionPoints = 0;
          const answer = this.playersGuess[player.dbid][this.currQuestionIndex]

          let trackData = {
            'distinct_id': player.dbid,
            'username': player.username,
            'game_name': this.$store.getters["space/config"].experience.title,
            'question': this.currQuestion.prompt,
            'question_id': this.currQuestion.id,
            'answer_id': answer ? answer.id : null,
            'answer': answer ? this.currQuestion.options.find(x => x.id == answer.id).option : null,
          }

          if (answer == null) {
            console.log("❌ No respondio ⏱", player.username)
            trackData.correct = false;
          }else if (answer.id == this.currQuestion.id) {
            questionPoints = PUNTOS_RESPUESTA_CORRECTA + (answer.remaining * PUNTOS_EXTRA_TIEMPO);
            let score = player.score + questionPoints;
            let correctAnswers = player.correctAnswers+1
            console.log("✅ Respuesta correcta", player.username, score, answer.remaining, answer.remaining* PUNTOS_EXTRA_TIEMPO)
            this.$store.commit("space/AddPlayerData", {
              dbid: player.dbid, // Para encontrar al usuario
              score,
              correctAnswers
            })
            player.questionPoints = questionPoints;
            trackData.correct = true;
          } else {
            console.log("❌ Respuesta incorrecta", player.username)
            trackData.correct = false;
          }

          // El envio el puntaje que suma a este jugador
          this.$socket.client.emit("messageToPlayer", { to: player.socketid, msg: "questionPoints", data: { questionPoints, score: player.score } })

          // Track Data Analytics
          console.log("Track data", trackData)
          this.$TrackEvent("Trivia Answer", trackData)

          // Fin codigo puntaje. Codigo timer
          if (++i < this.players.length) {
            promise = promise.then(function () {
              return new Promise(function (resolve) {
                setTimeout(function () {
                  resolve();
                  next();
                }, interval);
              });
            });
          } else {
            setTimeout(outerResolve, interval);
            // or just call outerResolve() if you don't want to wait after the last element
          }
        };
        next();
      });
    },
    SendStandings() {
      console.log("Send standings")
      // Score sort
      this.playersScoreboard = JSON.parse(JSON.stringify(this.players)) // Copia sin referencia
      this.playersScoreboard.sort((a, b) => b.score - a.score); // Los ordeno por puntaje

      // Envio las posiciones actuales y puntaje de todos los jugadores
      // Se las envio a todos
      const standings = this.playersScoreboard.map((player, index) => {
        return {
          dbid: player.dbid,
          username: player.username,
          score: player.score,
          correctAnswers: player.correctAnswers,
          standing: index + 1,
          questionPoints: player.questionPoints,
        }
      })
      this.$socket.client.emit("roomPlayersMessage", { type: "standings", standings })
    },
    SyncQuestionStatePlayers() {
      // tengo esta funcion por separado para poder administrar por separado el timing de la screen space de los players
      console.log("Question state", this.questionState)
      this.$socket.client.emit("roomPlayersMessage", { type: "questionState", state: this.questionState, currQuestionIndex: this.currQuestionIndex, questionSeed: this.questionSeed })
    },
  },
  watch: {
    ShowVideoLoop(show) {
      if (show) {
        this.$sfxStop('gameplaymusic')
        // this.$sfxPlay('waitingroommusic', { loop: true, fadein: true })
      } else {
        this.$sfxStop('waitingroommusic')
        this.$sfxPlay('gameplaymusic', { loop: true, fadein: true })
      }
    },
    async serverState(newState) {
      if(newState == 0){
        // Frenar musica de fondo
        this.$sfxStop('questionmusic')
      }
      if (newState == 1) {
        this.questionState = -1;
        this.currQuestionIndex = -1;
        this.currQuestion = undefined
        this.SyncQuestionStatePlayers()
      }

      if (newState == 2) {
        this.gameSeed = Date.now();
        this.$socket.client.emit("roomPlayersMessage", { type: "gameSeed", gameSeed: this.gameSeed, saveState: true })

        const MAX_QUESTIONS = this.options.config.limitQuestions ? this.options.config.amountQuestions : this.questions.length
        if(SORT_FUNCTION == 'bucket'){
          this.selectedQuestions = []
          for(let i = 0; i < MAX_QUESTIONS; i++){
            this.selectedQuestions.push(this.questions[this.questionIndex % this.questions.length])
            this.questionIndex++
          }
        }else{
          this.selectedQuestions = TriviaOptionsShuffle.GetQuestions(this.questions, this.gameSeed, MAX_QUESTIONS)
        }
        console.log("SORT_FUNCTION", SORT_FUNCTION)
        console.log("Preguntas elegidas", this.selectedQuestions)
        this.$socket.client.emit("roomPlayersMessage", { type: "gameQuestions", selectedQuestions: this.selectedQuestions, saveState: true })
        this.$sfxStop('waitingroommusic')
        this.$sfxStop('waitingroomstart')
        this.StartGame()
      }
    }
  },
  sockets: {
    playerJoined(){
      if(this.players.length == 0){
        // Primer jugador
        this.$sfxPlay('waitingroommusic', { loop: true, fadein: true })
        this.$sfxPlay('waitingroomstart')
      }
    },
    playerLeft(){
      Vue.nextTick(()=>{
        // console.log('alguien se fue')
        // Si el juego ya terminó no actualizo
        if(this.serverState != 3){
          this.playersScoreboard = JSON.parse(JSON.stringify(this.players))
        }
      })

      if(this.players.length == 1){
        // Ultimo jugador
        this.$sfxStop('waitingroommusic')
        this.$sfxStop('waitingroomstart')
      }
    },
    startCountdown(data) {
      console.log("⏳ Start countdown", data, "restante", data.timestamp - this.$time.now());
      console.log("DATE NOW", Date.now(), this.$time.now())
      console.log("offset", Date.now() - this.$time.now())
      this.remainingTimeToStart = "00:00";
      const endTime = data.timestamp;
      this.timerWebWorkers.postMessage({ endTime, delay: this.$time.delay });
    },
    SendAnswer(data) {
      // Player sent answer
      console.log("Player sent answer", data)
      this.playersAnswered++
      this.playersGuess[data.dbid][this.currQuestionIndex] =
      {
        id: data.answerid,
        remaining: this.remainingTimeQuestion
      }
      // Agrego el id de este jugador al array guessed de esta opcion dentro de currQuestion
      // Sirve para mostrar quien contesto cada opcion
      const player = this.players.find(x => x.dbid == data.dbid)
      this.currQuestion.options.find(x => x.id == data.answerid).guessed.push(player)

      if (this.playersAnswered == this.players.length) {
        console.log("Ya contestaron todes")
        this.questionClockRunning = false
        this.asyncTimer.clear()
        this.timerWebWorkers.postMessage({ pause:true });
        this.pausedNormalized = this.remainingTimeQuestion
        this.ShowResults()
      } else {
        this.$socket.client.emit("messageToPlayer", {
          to: player.socketid,
          msg: "waitingResponses" })
      }
    }
  },
  async mounted() {
  
    this.questions = this.options.config.limitQuestions ? TriviaOptionsShuffle.Shuffle(this.options.questions) : this.options.questions
    // console.log("** this.questions", this.questions)
    // console.log("timesync", this.$time.now())
    // Cuando un juegador se une, le seteo unas datas de base
    this.$store.dispatch("space/SetNewPlayersExtraData", { score: 0, correctAnswers: 0, index: 0 })

    this.timerWebWorkers = new Worker(new URL("@/workers/CountdownWorker.js", import.meta.url));
    this.timerWebWorkers.onmessage = ({ data }) => {
      // console.log(timeString, timeNormalized);
      this.remainingTimeToStart = data.timeString
      this.remainingTimeQuestion = data.timeNormalized
    }

    this.ResetGame();
    this.$sfxLoad(this.options.sounds,
      [
        'waitingroommusic',
        'waitingroomstart',
        'countdown321',
        'gameplaymusic',
        'newquestion',
        'questionmusic',
        'stopmusicbang',
        'winnerpodium'
      ]
    )
  },
};
</script>
